/*===========================
    Header
===========================*/
header {
	/*===== Header transparent =====*/
	&.header-absolute {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 99;
	}
	/*===== Header to area =====*/
	.header-top-area {
		padding: 20px 0;
		@media #{$xsm} {
			display: none;
		}
		.contact-list,
		.social-link {
			li {
				display: inline-block;
				font-weight: 700;
				font-size: 14px;
				text-transform: uppercase;
				margin-right: 25px;
				font-family: $oswald;
				&:last-child {
					margin-right: 0;
				}
				&,
				a {
					color: $white;
				}
				a:hover {
					color: $primary-color;
				}
			}
		}
		.social-link {
			text-align: right;
			li {
				margin-right: 0;
				margin-left: 25px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
	/*===== Main Menu =====*/
	.mainmenu-area {
		background-color: $white;
		position: relative;
		padding: 0 60px;
		@media #{$xl} {
			padding: 0 40px;
		}
		@media #{$lg} {
			padding: 0 30px;
		}
		@media #{$md} {
			padding: 15px 95px 15px 15px;
		}
		@media #{$sm} {
			padding: 15px 95px 15px 15px;
		}
		@media #{$xsm} {
			margin-top: 15px;
		}
		.main-menu {
			display: grid;
			grid-template-rows: 1;
			grid-template-columns: 150px 1fr;
			grid-column-gap: 75px;
			align-items: center;
			@media #{$xl} {
				grid-column-gap: 40px;
			}
			@media #{$lg} {
				grid-column-gap: 30px;
			}
			.logo {
				img {
					max-width: 150px;
					@media #{$tiny} {
						max-width: 120px;
					}
				}
			}
			.menu-items {
				ul li {
					display: inline-block;
					margin-right: 30px;
					position: relative;
					@media #{$xl} {
						margin-right: 15px;
					}
					@media #{$lg} {
						margin-right: 0;
					}
					&:last-child {
						margin-right: 0;
					}
					a {
						line-height: 120px;
						padding: 0 15px;
						color: $secondary-color;
						font-family: $oswald;
						font-weight: 700;
						text-transform: uppercase;
						font-size: 14px;
						@media #{$lg} {
							padding: 0 12px;
						}
						&:hover {
							color: $primary-color;
						}
					}
					&.active {
						& > a {
							color: $primary-color;
						}
					}
					.submenu {
						position: absolute;
						left: 0;
						top: 120%;
						width: 220px;
						background-color: $white;
						opacity: 0;
						visibility: hidden;
						@include transition(0.3s);
						z-index: 99;
						box-shadow: 0px 8px 16px 0px rgba(73, 167, 211, 0.06);
						& > li {
							position: relative;
							display: block;
							margin: 0;
							a {
								display: block;
								padding: 0 20px;
								position: relative;
								color: $text-color;
								@include transition(0.3s);
								border-radius: 0;
								line-height: 50px;
								&:hover {
									background-color: $primary-color;
									color: $white;
								}
							}
							.submenu {
								position: absolute;
								left: 100%;
								top: 30%;
								width: 220px;
								background-color: $white;
								visibility: hidden;
								opacity: 0;
							}
							&:hover {
								.submenu {
									opacity: 1;
									visibility: visible;
									top: 0;
								}
							}
						}
					}
					&:hover {
						.submenu {
							visibility: visible;
							opacity: 1;
							top: 100%;
						}
					}
				}
			}
		}
		.main-right {
			display: flex;
			justify-content: right;
			align-items: center;
			@media #{$xsm} {
				display: none;
			}
			.offcanvas-trigger {
				margin-left: 20px;
				height: 60px;
				width: 60px;
				line-height: 60px;
				background-color: $primary-color;
				border: 2px solid $primary-color;
				color: $white;
				text-align: center;
				@include transition(0.3s);

				@media #{$sm} {
					display: none;
				}

				&:hover {
					background-color: $secondary-color;
					border-color: $secondary-color;
				}
				i {
					font-size: 30px;
					position: relative;
					top: 5px;
				}
			}
		}
	}
	/*===== Mobile Menu =====*/
	.mobile-menu.mean-container {
		.mean-bar {
			background-color: transparent;
			padding: 0;
			min-height: 0;
			a.meanmenu-reveal {
				margin-right: 15px;
				margin-top: -75px;
				border: 2px solid rgba(4, 29, 56, 0.1);
				padding: 17px 15px;
				width: 26px;
				z-index: 222;
				@media #{$tiny} {
					margin-top: -65px;
					padding: 12px 10px;
				}
				span {
					background-color: $secondary-color;
				}
			}
		}
		.mean-nav {
			margin-top: 0;
			background-color: $secondary-color;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			z-index: 99;
			ul li {
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
				&:last-child {
					border: none;
				}
				a {
					border: none;
					padding: 0;
					padding: 15px 20px;
					width: 100%;
					font-size: 14px;
				}
				li {
					&:first-child {
						border-top: 1px solid rgba(255, 255, 255, 0.2);
					}
					a {
						padding: 15px 40px;
					}
					li {
						border-top: 1px solid rgba(255, 255, 255, 0.2);

						a {
							padding: 15px 60px;
						}
					}
				}
				a.mean-expand {
					border: none !important;
					width: 50px;
					height: 53px;
					padding: 0 !important;
					line-height: 53px;
					margin-top: 0;
					&:hover {
						background: transparent;
					}
				}
			}
		}
	}
	/*===== Header two =====*/
	&.header-two {
		.header-top-area {
			padding: 15px 0;
			background-color: $secondary-color;
		}
		.mainmenu-area {
			border-bottom: 1px solid #f1f1f1;
			padding: 0;
			@media #{$xl} {
				padding: 0;
			}
			@media #{$lg} {
				padding: 0;
			}
			@media #{$md} {
				padding: 15px 95px 15px 0;
			}
			@media #{$sm} {
				padding: 15px 95px 15px 0;
			}
			@media #{$xsm} {
				margin-top: 0;
			}
			.main-menu {
				grid-column-gap: 90px;
				@media #{$xl} {
					grid-column-gap: 60px;
				}
				@media #{$lg} {
					grid-column-gap: 30px;
				}
			}
			.main-right {
				text-align: right;
				.offcanvas-trigger {
					margin-left: 50px;
				}
				.quote-icon {
					text-align: left;
					padding-left: 80px;
					position: relative;
					.icon {
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
					span {
						font-weight: 700;
						font-size: 14px;
						color: $secondary-color;
						font-family: $oswald;
						line-height: 24px;
						text-transform: uppercase;
						span {
							display: block;
							font-size: 20px;
							color: $primary-color;
							@media #{$sm} {
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
	/*===== Header three =====*/
	&.header-three {
		.mainmenu-area {
			background-color: transparent;
			padding: 15px 0;
			@media #{$md} {
				padding: 15px 95px 15px 0;
			}
			@media #{$sm} {
				padding: 15px 95px 15px 0;
			}
			.main-menu {
				grid-column-gap: 75px;
				@media #{$xl} {
					grid-column-gap: 40px;
				}
				@media #{$lg} {
					grid-column-gap: 30px;
				}
				.logo {
					img.sticky-logo {
						display: none;
					}
				}
				.menu-items {
					ul li {
						margin-right: 30px;
						@media #{$lg} {
							margin-right: 0;
						}
						a {
							color: $white;
							line-height: 90px;
						}
						&.active {
							& > a {
								color: $primary-color;
							}
						}
					}
				}
			}
			.main-right {
				.main-btn.btn-filled:hover {
					background-color: $white;
					&::after,
					&::before {
						background-color: $secondary-color;
					}
				}
				.offcanvas-trigger {
					background-color: $white;
					border-color: $white;
					color: $secondary-color;
					&:hover {
						background-color: $primary-color;
						border-color: $primary-color;
					}
				}
			}
		}
		.mobile-menu.mean-container {
			.mean-bar {
				a.meanmenu-reveal {
					border-color: $white;
					background-color: $white;
				}
			}
		}

		&.sticky {
			.mainmenu-area .main-menu .logo img {
				&.sticky-logo {
					display: block;
				}
				&.normal-logo {
					display: none;
				}
			}
		}
	}
	/*===== Sticky Header =====*/
	&.sticky {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background: $white;
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
		z-index: 99;
		animation: sticky 1.2s;
		.mainmenu-area {
			margin-top: 0;
			.main-menu .menu-items ul li {
				a {
					line-height: 90px;
				}
			}
		}
		.header-top-area {
			display: none;
		}
		&.header-three {
			.mainmenu-area {
				padding: 0;
				@media #{$md} {
					padding: 15px 95px 15px 0;
				}
				@media #{$sm} {
					padding: 15px 95px 15px 0;
				}
				.main-menu .menu-items ul li {
					a {
						color: $text-color;
					}
				}
				.offcanvas-trigger {
					background-color: $primary-color;
					border-color: $primary-color;
					color: $secondary-color;
					&:hover {
						background: transparent;
						border-color: rgba(4, 29, 56, 0.1);
					}
				}
			}
			.mobile-menu.mean-container {
				.mean-bar {
					a.meanmenu-reveal {
						border-color: rgba(4, 29, 56, 0.1);
						background-color: transparent;
					}
				}
			}
		}
	}

	/* off canvas menu */
	.off-canvas-wrap {
		position: fixed;
		width: 450px;
		top: 0;
		min-height: 100vh;
		right: 0;
		transition: all 0.4s;
		z-index: 9999999;
		margin-right: -450px;
		box-shadow: 0px 8px 16px 0px rgba(73, 167, 211, 0.08);
		text-align: left;
		&.show-off-canvas {
			margin-right: 0;
		}
		.overly {
			position: fixed;
			height: 100vh;
			width: 100%;
			z-index: 998;
			background-color: $black;
			left: 0;
			top: 0;
			transition: all 0.4s;
			opacity: 0;
			visibility: hidden;
			cursor: pointer;
			&.show-overly {
				visibility: visible;
				opacity: 0.6;
			}
		}
		.off-canvas-widget {
			padding: 90px 50px;
			background-color: $white;
			height: 100vh;
			position: relative;
			z-index: 999;
			a.off-canvas-close {
				position: absolute;
				right: 50px;
				top: 40px;
				font-size: 30px;
				color: $text-color;
			}
			.widget {
				margin-bottom: 50px;
				&:last-child {
					margin-bottom: 0;
				}
				.widget-title {
					font-size: 20px;
					margin-bottom: 20px;
					text-transform: uppercase;
					letter-spacing: -1px;
				}
				&.recent-post {
					ul {
						li {
							display: grid;
							grid-template-rows: auto;
							grid-template-columns: 120px 1fr;
							margin-bottom: 30px;
							&:last-child {
								margin-bottom: 0;
							}
							.post-img {
								background-size: cover;
								background-position: center;
							}
							.post-content {
								padding-left: 20px;
								h6 {
									margin-bottom: 15px;
									font-size: 18px;
									text-transform: uppercase;
									letter-spacing: -1px;
									line-height: 1.3;
								}
								.time {
									font-size: 15px;
									font-weight: 500;
									i {
										margin-right: 10px;
									}
								}
							}
						}
					}
				}
				&.contact-widget {
					ul {
						li {
							font-size: 18px;
							color: $text-color;
							font-weight: 600;
							margin-bottom: 10px;
							padding-left: 25px;
							position: relative;
							i {
								position: absolute;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
								font-size: 15px;
							}
							a {
								color: $text-color;
							}
						}
					}
				}
				&.social-widget {
					ul {
						li {
							a {
								height: 40px;
								width: 40px;
								line-height: 40px;
								border: 1px solid $text-color;
								color: $text-color;
								border-radius: 50%;
								text-align: center;
								font-size: 15px;
								margin-right: 10px;
								&:hover {
									background-color: $primary-color;
									color: $white;
									border-color: $primary-color;
								}
							}
						}
					}
				}
			}
		}
	}
}
